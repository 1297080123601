import React, {useState, useEffect} from 'react'
import {Stack, HStack, Box, Flex, Checkbox, Heading, Text,} from '@chakra-ui/react'
import {ChakraSelectLabel, InputLabel} from '../../../../components/Theme'
import NavigateModal from './NavigateModal'
import Loading from "../../../Loading";
import {useGetRecommended} from "../../../../core/hooks";
import {ACCESSORIES_FILTER, PRODUCT_TYPES} from "../../../../constants/types";
import {ReactComponent as ArrowDownIcon} from "../../../../assets/downArrow.svg";
import styles from "../styles";

const Accessory = ({
                       description,
                       project,
                       setType,
                       accessory,
                       fromAll,
                       setAccessory,
                       luminaire,
                       createKey,
                       setCreateKey,
                       onClose,
                       setRecent,
                       area,
                       type,
                       setArea,
                       luminaireId,
                       customPricing,
                        setCustomPricing
                   }) => {
    const [fetchedDataAccess, setFetchedDataAccess] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [accessoryAirtable, setAccessoryAirtable] = useState([]);
    const [buyAmerican, setBuyAmerican] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filters, setFilters] = useState({
        type: '',
        manufacturer: '',
        model: '',
        productCode: '',
        depth: '',
        length: '',
        width: '',
        diameter: ''
    })
    const {data, isLoading, refetch} = useGetRecommended(
        {
            condition: {
                table: PRODUCT_TYPES["5"],
                filter: ACCESSORIES_FILTER(luminaire),
                projectId: project?.id,
            }
        }
    )

    const validate = ({fields}) => {
        let bool = true
        if (customPricing.accessory && bool) {
            (!fields.hasOwnProperty('Price Per Unit ($)') || !fields['Price Per Unit ($)'] || Number(fields['Price Per Unit ($)']) == 0) ? bool = true : bool = false
        }
        if (buyAmerican && bool){
            !!fields['Buy America'] ? bool = fields['Buy America'] === 'Yes' : bool = false
        }
        if (!!filters?.manufacturer && bool) {
            !!fields['Product Type'] ? bool = fields['Product Type'].toLowerCase().includes(filters.type.toLowerCase()) : bool = false
        }
        if (!!filters?.productCode && bool) {
            !!fields['Product Code'] ? bool = fields['Product Code'].toLowerCase().includes(filters.manufacturer.toLowerCase()) : bool = false
        }
        return bool
    }

    const setLedsForAll = (areas) => {
        const groupedObjects = new Map();
        areas.forEach((area) => {
            area?.luminaires?.forEach((obj) => {
                const { id, qty, fixtureMountHeight, globalQty, globalQtyLamp, ...rest } = obj;
                const key = JSON.stringify(rest);

                if (!groupedObjects.has(key)) {
                    groupedObjects.set(key, {
                        ...rest,
                        globalQty: Number(qty),
                        globalQtyLamp: Number(globalQtyLamp),
                        count: 1
                    });
                } else {
                    const existing = groupedObjects.get(key);
                    groupedObjects.set(key, {
                        ...existing,
                        globalQty: existing.globalQty + Number(qty),
                        globalQtyLamp: existing.globalQtyLamp + Number(globalQtyLamp),
                        count: existing.count + 1
                    });
                }
            });
        });
        return Array.from(groupedObjects.values()).filter(
            (group) => group.count > 1
        );
    }

    const create = () => {
        let index = 0;
        let ides = setLedsForAll(area);
        let newArray = [];

        if (fromAll) {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((led) => {
                        let newObj = { ...led };

                        if (ides.length > 0) {
                            for (let j = 0; j < ides.length; j++) {
                                let { id, qty, fixtureMountHeight, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = ides[j];
                                let { id: targetId, qty: targetQty, fixtureMountHeight: targetHeight,globalRecomendedFixtureQty: targetglobalRecomendedFixtureQty, globalQty: targetGlobalQty, globalQtyLamp: targetGlobalQtyLamp, ...obj } = newObj;
                                let { globalQty: glob, globalQtyLamp: globLamp, ...lumin } = luminaire;

                                if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                    if (ides[j].id === newObj.id) {
                                        newObj = {
                                            ...newObj,
                                            ...accessory,
                                        };
                                        index = i;
                                    }
                                } else if (JSON.stringify(lumin) === JSON.stringify(obj)) {
                                    newObj = {
                                        ...newObj,
                                        ...accessory,
                                    };
                                    index = i;
                                }
                            }
                        } else {
                            let { id, qty, fixtureMountHeight, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = newObj;
                            let { globalQty: glob, globalQtyLamp: globLamp, globalRecomendedFixtureQty: globFixtureRecom, ...lumin } = luminaire;
                            if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                newObj = {
                                    ...newObj,
                                    ...accessory,
                                };
                                index = i;
                            }
                        }

                        return newObj;
                    })
                };
            });
        } else {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((luminaire) => {
                        let newObj = { ...luminaire };

                        if (newObj.id === luminaireId || ides.includes(newObj.id)) {
                            newObj = {
                                ...newObj,
                                ...accessory,
                            };
                            index = i;
                        }

                        return newObj;
                    })
                };
            });
        }
        setArea(newArray);
        // setRecent([...newArray])
    }


    useEffect(() => {
        if (createKey === true) {
            create()
            setCreateKey(false)
            onClose()
        }
    },[createKey])

    const handleSelect = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fetchedDataAccess?.filter((item) => validate(item) === true).map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (!data) return
        setFetchedDataAccess(data.data.data)
        setSelectedItems(accessory.hasOwnProperty('accessoryRecomendedCustomPriceIdes') ? accessory.accessoryRecomendedCustomPriceIdes : [])
        setAccessoryAirtable(accessory.hasOwnProperty('accessoryRecomendedAirtableArray') ? accessory.accessoryRecomendedAirtableArray : [])
    }, [data])

    useEffect(() => {
        if (selectedItems.length > 0) {
            if (customPricing.accessory) {
                setAccessory({
                    ...accessory,
                    accessoryRecomendedCustomPriceIdes: selectedItems,
                })
            } else {
                setAccessory({
                    ...accessory,
                    accessoryRecomendedCustomPriceIdes: [],
                })
            }
        } else {
            setAccessory({
                ...accessory,
                accessoryRecomendedCustomPriceIdes: [],
            })
        }
    }, [selectedItems])

    useEffect(() => {
        if (accessoryAirtable.length > 0) {
            setAccessory({
                ...accessory,
                accessoryRecomendedAirtable: JSON.stringify(accessoryAirtable[0].fields),
                accessoryRecomendedAirtableArray: accessoryAirtable,
                accessoryRecomendedDescription: accessoryAirtable[0].fields['FE Product Description']
            })
        } else {
            setAccessory({
                ...accessory,
                accessoryRecomendedAirtable: [],
                accessoryRecomendedAirtableArray: [],
                accessoryRecomendedDescription: '',
            })
        }
    }, [accessoryAirtable])

    useEffect(() => {
        if (type === 'accessory') {
            refetch()
        }
    }, [type])

    if (isLoading) return <Loading height='100%'/>

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('control')
                }}
                rightAction={() => {
                    create()
                    setType('fixture')
                }}
                left="Control"
                right="Fixture"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    accessories details
                </Box>
            </HStack>
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel
                    height='46px'
                    maxW="80px"
                    type="number"
                    value={accessory?.accessoryRecomendedQty}
                    onChange={(e) => setAccessory({...accessory, accessoryRecomendedQty: e.target.value})}
                    pattern="[0-9]*" label="QTY"/>
                <Box position='relative' width='100%' height='46px' css={styles.styledList}>
                    <Flex
                        alignItems='center'
                        onClick={() => {
                            refetch({
                                condition: {
                                    table: PRODUCT_TYPES["5"],
                                    filter: ACCESSORIES_FILTER(luminaire),
                                    projectId: project?.id,
                                }
                            })
                            setIsOpen(!isOpen)
                        }}
                        bg='#EEEEEE'
                        cursor='pointer'
                        border='1px solid #ccc'
                        borderBottom='none'
                        borderBottomLeftRadius='6px'
                        borderBottomRightRadius='6px'
                        position='relative'
                        fontSize='18px'
                        p='10px 30px'>
                        {accessoryAirtable?.length > 0 ? accessoryAirtable.map(item => item.fields["FE Product Description"]).join(', ') : "No Change"}
                        <ArrowDownIcon style={{position: 'absolute', right: '10px', top: '18px'}}/>
                    </Flex>
                    {isOpen && (
                        <ul style={{
                            position: 'absolute',
                            top: '99%',
                            left: '0',
                            right: '0',
                            border: '1px solid #ccc',
                            margin: 0,
                            padding: 0,
                            listStyle: 'none',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            maxHeight: '500px',
                            overflowY: 'auto'
                        }}>
                            {customPricing.accessory && (
                                <li style={{
                                    padding: '8px 8px 0 8px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        _checked={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _hover={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _focus={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                borderColor: 'black',
                                                boxShadow: 'none'
                                            }
                                        }}
                                        _focusVisible={{
                                            "& .chakra-checkbox__control": {outline: 'none', boxShadow: 'none'}
                                        }}
                                        iconColor='black'
                                        isChecked={selectAll}
                                        mr='8px'
                                        borderColor='#222222'
                                        onChange={handleSelectAll}>
                                        <Heading m={0} fontSize='14px'>Select All</Heading>
                                    </Checkbox>
                                </li>
                            )}
                            {fetchedDataAccess?.filter((item) => validate(item) === true).map(item => (
                                <li
                                    className='list-item'
                                    key={item.id} style={{
                                    padding: customPricing.accessory ? '8px 8px 0 8px' : '8px 30px 0 30px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    {
                                        customPricing.accessory ? (
                                            <>
                                                <Checkbox
                                                    _checked={{
                                                        "& .chakra-checkbox__control": {
                                                            background: "#FFE68D",
                                                            boxShadow: 'none',
                                                            borderColor: 'black'
                                                        }
                                                    }}
                                                    _hover={{
                                                        "& .chakra-checkbox__control": {
                                                            background: "#FFE68D",
                                                            boxShadow: 'none',
                                                            borderColor: 'black'
                                                        }
                                                    }}
                                                    iconColor='black'
                                                    borderColor='#222222'
                                                    isChecked={selectedItems.includes(item.id)}
                                                    onChange={() => handleSelect(item.id)}
                                                    mr='8px'>
                                                </Checkbox>
                                                <Text as='span'
                                                      onClick={(e) => {
                                                          setAccessoryAirtable((prev) => {
                                                              if (prev.some(accessory => accessory.id === item.id)) {
                                                                  return prev.filter(accessory => accessory.id !== item.id);
                                                              } else {
                                                                  return [item];
                                                              }
                                                          })
                                                      }}
                                                      fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                <Text as='span' onClick={(e) => setAccessoryAirtable([item])}
                                                      fontSize='18px'>${item.fields["Price Per Unit ($)"]}</Text>
                                            </>
                                        ) : (
                                            <>
                                                <Text
                                                    onClick={(e) => {
                                                        setAccessoryAirtable((prev) => {
                                                            if (prev.some(accessory => accessory.id === item.id)) {
                                                                return prev.filter(accessory => accessory.id !== item.id);
                                                            } else {
                                                                return [item];
                                                            }
                                                        })
                                                    }}
                                                    as='span'
                                                    fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                <Text as='span'
                                                      fontSize='18px'>${item.fields["Price Per Unit ($)"]}</Text>
                                            </>
                                        )
                                    }

                                    <Flex
                                        display={customPricing.control && !item.fields["Price Per Unit ($)"] ? 'flex' : 'none'}
                                        alignItems='center' justifyContent='center' borderRadius='2px' bg='#FF7F56'
                                        width='20px' height='20px' position='absolute' right='30px' top='8px'>
                                        ?
                                        {/*<DollarIcon />*/}
                                    </Flex>
                                </li>
                            ))}
                        </ul>
                    )}
                </Box>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel label="Type:" placeholder="Select" value={accessory?.accessoryRecomendedType}
                                   onChange={(e) => {
                                       setFilters({...filters, type: e.target.value})
                                       setAccessory({...accessory, accessoryRecomendedType: e.target.value})
                                   }}>
                    <option value="mounting bracket">Mounting Bracket</option>
                    <option value="protective lens cover">Protective Lens Cover</option>
                    <option value="light shield">Light Shield</option>
                </ChakraSelectLabel>
                <InputLabel value={accessory?.accessoryRecomendedManufacturer}
                            onChange={(e) => {
                                setFilters({...filters, manufacturer: e.target.value})
                                setAccessory({...accessory, accessoryRecomendedManufacturer: e.target.value})
                            }}
                            label="Manufacturer:"/>
                <InputLabel value={accessory?.accessoryRecomendedModel} label="Model:"
                            onChange={(e) => {
                                setFilters({...filters, model: e.target.value})
                                setAccessory({...accessory, accessoryRecomendedModel: e.target.value})
                            }}/>
                <InputLabel value={accessory?.accessoryRecomendedProductCode} label="Product Code:"
                            onChange={(e) => {
                                setFilters({...filters, productCode: e.target.value})
                                setAccessory({...accessory, accessoryRecomendedProductCode: e.target.value})
                            }}/>
            </HStack>
            <HStack spacing="5">
                <InputLabel
                    height='46px' type="number" pattern="[0-9]*" value={filters?.length}
                    onChange={(e) => {
                        setFilters({...filters, length: e.target.value})
                    }}
                    label="Dimensions-Length (in):"/>
                <InputLabel
                    height='46px' type="number" pattern="[0-9]*" value={filters?.width}
                    onChange={(e) => {
                        setFilters({...filters, width: e.target.value})
                    }}
                    label="Dimensions-Width (in):"/>
                <InputLabel
                    height='46px' type="number" value={filters?.depth} label="Dimensions-Depth (in):"
                    onChange={(e) => {
                        setFilters({...filters, depth: e.target.value})
                    }}/>
                <InputLabel
                    height='46px' type="number" value={filters?.diameter}
                    onChange={(e) => {
                        setFilters({...filters, diameter: e.target.value})
                    }}
                    label="Dimensions-Diameter (in):"/>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <HStack spacing='60px'>
                    <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                        Show Custom <br/>Price Options
                    </Heading>
                    <Checkbox
                        _checked={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        _hover={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        iconColor='black'
                        onChange={(e) => {
                            refetch()
                            if (!e.target.isChecked) {
                                setSelectedItems([])
                                setAccessoryAirtable([])
                            }
                            setCustomPricing({...customPricing, accessory: !customPricing.accessory})
                        }}
                        isChecked={customPricing.accessory} color='#A9A9A9' width='20px' height='20px'
                        borderColor='#2C628D'>
                        Yes
                    </Checkbox>
                </HStack>
                <HStack spacing='40px' width='40%'>
                    <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                        Buy American
                    </Heading>
                    <Checkbox
                        _checked={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        _hover={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        onChange={() => {
                            setBuyAmerican(!buyAmerican)
                        }}
                        isChecked={buyAmerican}
                        iconColor='black'
                        color='#A9A9A9' width='20px' height='20px' borderColor='#2C628D'>
                        Yes
                    </Checkbox>
                </HStack>
            </HStack>

        </Stack>
    )
}

export default Accessory
