import React, {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {
    Flex,
    Text,
    Box,
    Heading,
    Divider,
    Input,
    HStack,
    Select,
    Grid,
    Button,
    GridItem, useDisclosure, useToast,
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {SPECIALITIES} from '../../constants'
import styles from './styles'
import ChartCompany from '../../components/Theme/ChartCompany'
import {Incrementer, InputLabel, TextareaLabel} from "../../components/Theme";
import HeaderCompanyAdmin from "../CompanyEditAdmin/Header";
import {useForm} from "react-hook-form";
import {STATES} from "../../constants/serviceableAreas"
import ServiceableAreas from "../EditCompany/Modals/ServiceableAreas";
import {ReactComponent as Edit_Modal} from "../../assets/edit_modal.svg";
import {useCreateCompany, useUser} from "../../core/hooks";
import {COMPANY_OBJECT} from "../../constants/company/companyEnum";

const initialScore = {
    office: 0,
    industrial: 0,
    retail: 0,
    multi: 0,
    hospitality: 0,
    parking: 0,
    horticulture: 0,
    healthcare: 0,
    data: 0,
    other: 0,
}

const CompanyDetail = () => {
    const params = useParams()
    const {user} = useUser()
    const history = useHistory()
    const toast = useToast()
    const [score, setScore] = useState(initialScore)
    const [serviceArea, setServiceArea] = useState([])
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [error, setError] = useState('')
    const {createCompany, isLoading} = useCreateCompany()
    const {formState: {errors}, register, handleSubmit, setValue, watch}
        = useForm({defaultValues: COMPANY_OBJECT})
    const image = watch('image', '')

    const submit = (data) => {
        if (serviceArea.length < 1 && params.type === 'eu') {
            return setError('Chose area')
        }
        setError('')
        const input = {
            ...data,
            ownerId: user?.id,
            status: 'review',
            type: params?.type === 'reu' ? 'Real Estate' : params?.type === 'su' ? 'Luminaire Types' : 'Electrical Contractor',
            country: 'USA',
            serviceArea: [JSON.stringify(serviceArea)],
            propertyPriorities: [JSON.stringify(score)],
            seen: false,
        }
        createCompany(
            input,
            {
                onSuccess: ({data}) => {
                    setError('')
                    toast({
                        render: () => (
                            <Flex
                                overflow='hidden'
                                position='relative'
                                justifyContent='center'
                                flexDirection='column'
                                borderRadius='4px 0px 0px 4px'
                                marginTop='80px'
                                color='black'
                                bg='#8FCF74'
                                pl={3}
                                width='530px'
                                fontWeight='600'
                                fontSize='14px'
                                height='40px'>
                                <Flex
                                    alignItems='center'>
                                    <Text
                                        ml={2}>
                                        Your Company information was submitted!
                                    </Text>
                                </Flex>
                                <Box
                                    css={styles.toast}
                                    position='absolute'
                                    left='5px'
                                    bottom='0'
                                    mt={1}
                                    borderRadius='6px'
                                    backgroundColor='#D5D5D5'
                                    height='3px'
                                    width='40px'>
                                </Box>
                            </Flex>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    history.goBack()
                },
                onError: (e) => setError(e.response.data.errorMessage),
            },
        )
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            return setError(`Please fill in the required fields to submit Company Details...`)
        }
    }, [errors])

    return (
        <>
            <form
                noValidate
                onSubmit={handleSubmit(submit)}>
                <Navbar
                    minW="1240px"
                    title={(
                        <Flex>
                            <Link
                                to="/companies">
                                Companies
                            </Link>
                                &nbsp;/&nbsp;
                                {
                                    params.type === 'reu' ? 'Real Estate' :
                                        params.type === 'su' ? 'Luminaire Types' :
                                            params.type === 'eu' ? 'Electrical Contractor' : ''
                                }
                        </Flex>
                    )}>
                    <Button
                        bg="#C2EF62"
                        color="black"
                        mr={8}
                        ml={12}
                        px={8}
                        isLoading={isLoading}
                        w="135px"
                        type="submit"
                        h="40px">
                        <img
                            src="/images/done.png"
                            alt="done"/>
                        <Text
                            fontWeight="700"
                            ml={1}
                            mt="2px">
                            Done
                        </Text>
                    </Button>
                </Navbar>
                <Box
                    minW="1240px"
                    margin="auto"
                    maxW="1240px"
                    css={styles.container}
                    px={14}
                    pt={8}
                    pb={16}
                    borderRight="1px solid"
                    borderLeft="1px solid"
                    borderColor="#DBDADA"
                    bg="#f5f5f5">
                    <Flex
                        w="full"
                        direction="row"
                        flexWrap="wrap"
                        position='relative'
                        alignItems="center"
                        justifyContent="space-between"
                        mt={4}>
                        <Box>
                            <HeaderCompanyAdmin
                                setValue={setValue}
                                image={image}/>
                        </Box>
                        <Box
                            width="510px"
                            mb={12}>
                            <Input
                                isInvalid={!!errors.name}
                                {...register('name',{required: true})}
                                fontWeight="700"
                                bg="white"
                                fontSize="24px"
                                color="black !important"
                                border="1px solid #C4C4C4 !important"
                                mr={7}/>
                        </Box>
                        <Box
                            mb={12}
                            marginLeft={2}
                            marginRight={2}>
                            <Text>
                                Company Type:
                            </Text>
                        </Box>
                        <Box
                            width="30%"
                            mb={12}>
                            <Input
                                disabled
                                value={
                                    params.type === 'reu' ? 'Real Estate' :
                                        params.type === 'su' ? 'Luminaire Types' :
                                            params.type === 'eu' ? 'Electrical Contractor' : ''
                                }
                                bg="white"
                                border="1px solid #C4C4C4 !important"
                                mr={7}/>
                        </Box>
                    </Flex>
                    <Divider
                        my={10}
                        borderColor="#DADADA"
                        border="3px solid"
                        orientation="horizontal"/>
                    {
                        error && (
                            <Text
                                color="red.100"
                                fontSize="m">
                                {error}
                            </Text>
                        )
                    }
                    <Flex
                        w="100%">
                        <InputLabel
                            isInvalid={!!errors.address}
                            {...register('address',{required: true})}
                            mr={7}
                            label="Address:"/>
                        <InputLabel
                            {...register('address2')}
                            ml={5}
                            label="Address Line 2:"/>
                    </Flex>
                    <HStack
                        pt="60px"
                        spacing="16"
                        alignItems="center"
                        justifyContent="space-between">
                        <InputLabel
                            isInvalid={!!errors.city}
                            {...register('city',{required: true})}
                            maxW="170px"
                            label="City:"/>
                        <label>
                            <Text
                                as="span"
                                width="105px"
                                color="#2C628D"
                                fontWeight="600"
                                fontFamily='Titillium Web'
                                fontSize='md'
                                display="inline-block"
                                pb="4px">
                                State:
                            </Text>
                            <Select
                                label="State:"
                                mt="2px"
                                isInvalid={!!errors.state}
                                {...register('state',{required: true})}
                                border="1px solid #C4C4C4 !important"
                                name="state"
                                fontSize='md'
                                height='50px'
                                bg="white" maxW="105px">
                                {
                                    STATES.map((item, idx) => (
                                        <option
                                            key={idx}>
                                            {item}
                                        </option>
                                    ))
                                }
                            </Select>
                        </label>
                        <InputLabel
                            isInvalid={!!errors.zipCode}
                            {...register('zipCode',{required: true})}
                            maxW="105px"
                            label="Zip Code:"/>
                        <InputLabel
                            value='USA'
                            readOnly
                            maxW="540px"
                            label="Country:"/>
                    </HStack>
                    <Flex
                        w="100%"
                        pt="60px">
                        <InputLabel
                            readOnly
                            mr={7}
                            label="Associated Users:"/>
                        <InputLabel
                            ml={7}
                            isInvalid={!!errors.website}
                            {...register('website',{required: true})}
                            label="Website:"/>
                    </Flex>
                    <Flex
                        w="100%"
                        pt="20px">
                        <TextareaLabel
                            isInvalid={!!errors.notes}
                            {...register('notes',{required: true})}
                            label="Notes:"/>
                    </Flex>
                    <Divider
                        my={10}
                        borderColor="#DADADA"
                        border="3px solid"
                        orientation="horizontal"/>
                    <Heading
                        mb={10}
                        fontSize="xl"
                        as="h2">
                        {
                            user?.type === 'Reu' ? (
                                'Property Types:'
                            ) : ('Serviceable Property Types:')
                        }
                    </Heading>
                    <Flex
                        alignItems="center"
                        justifyContent="space-between">
                        <Grid
                            templateColumns="repeat(2, 1fr)"
                            gap={5}>
                            {
                                SPECIALITIES.map((interest) => (
                                    <GridItem
                                        key={interest.title}
                                        colSpan={1}>
                                        <Incrementer
                                            plus
                                            score={score}
                                            setScore={setScore}
                                            title={interest.title}
                                            color={interest.active}/>
                                    </GridItem>
                                ))
                            }
                        </Grid>
                        <ChartCompany
                            score={score}/>
                    </Flex>
                    <Divider
                        mb="4.25rem"
                        borderColor="#DADADA"
                        border="3px solid"
                        display={params.type === 'eu' ? 'block' : 'none'}
                        orientation="horizontal"/>
                    <Box
                        w='full'
                        display={params.type === 'eu' ? 'block' : 'none'}>
                        <Flex
                            w="100%">
                            <Text
                                fontWeight='600'
                                mb={5}
                                fontSize='18px'>
                                Serviceable Areas:
                            </Text>
                        </Flex>
                        <Flex
                            w="100%">
                            <Text
                                fontWeight='600'
                                fontSize='14px'
                                color="#2C628D">
                                Serviceable Areas - States & Counties
                            </Text>
                        </Flex>
                        <Flex
                            w="100%"
                            border="1px solid #C4C4C4"
                            borderRadius="5px"
                            justifyContent="space-between"
                            p="10px"
                            mb={20}>
                            <Text
                                fontWeight='400'
                                fontSize='18px'>
                                {(() => {
                                    if (serviceArea.length > 0) {
                                        let a = serviceArea?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                        let res = a[a.length - 1].slice(0, -1)
                                        a.pop()
                                        a.push(res)
                                        return a?.toString()?.replace(/(.{100})..+/, "$1…")
                                    }
                                })()}
                            </Text>
                            <ServiceableAreas
                                serviceArea={serviceArea}
                                setServiceArea={setServiceArea}
                                isOpen={isOpen}
                                onClose={onClose}/>
                            <Button
                                onClick={onOpen}
                                leftIcon={<Edit_Modal/>}
                                w="60px"
                                height="22px"
                                variant="primary"
                                backgroundColor="#FFE68D">
                                <Text
                                    mt={1}
                                    color="black">
                                    Edit
                                </Text>
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </form>
        </>
    )
}

export default CompanyDetail
