import {Button, Flex, Grid, GridItem, Heading, Text} from "@chakra-ui/react";
import styles from "./styles";
import {ReactComponent as Edit_Modal} from "../../../assets/edit_modal.svg";
import React from "react";
import {ReactComponent as DollarIcon} from "../../../assets/Icons/dollarIcon.svg";
import {ReactComponent as PendingIcon} from "../../../assets/Icons/pendingIcon.svg";
import {ReactComponent as ReviewIcon} from "../../../assets/Icons/reviewIcon.svg";

function isJsonObject(variable) {
    try {
        const jsonObject = JSON.parse(variable);
        return true
    } catch (error) {
        return false
    }
}

const RecommendedLampGrid = ({item, setType, onOpenLampView, onOpen, customPricing,setViewType, onOpenAccessoryView}) => (
    <GridItem border='1px solid' borderColor="#8FCF74" bg='white'>
        <GridItem border='1px solid' borderColor="#C4C4C4" bg='white'>
            <Grid templateColumns='repeat(8, 1fr)' p={4} columnGap={6} rowGap={2}>
                <GridItem colSpan={2}>
                    <Heading color="grey.200" fontSize="md">COMPONENT</Heading>
                </GridItem>
                <GridItem colSpan={1}>
                    <Heading color="grey.200" fontSize="md">QTY</Heading>
                </GridItem>
                <GridItem colSpan={5}>
                    <Heading color="grey.200" fontSize="md">DESCRIPTION</Heading>
                </GridItem>
                <GridItem colSpan={2} d='flex' alignItems='center'>
                    <Text fontSize="md">Fixture</Text>
                </GridItem>
                <GridItem colSpan={1} d='flex' alignItems='center'>
                    <Flex
                        borderColor={item?.fixtureRecomendedAirtable && customPricing.fixture && isJsonObject(item?.fixtureRecomendedAirtable) ? JSON.parse(item.fixtureRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.fixtureRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        css={styles.panelQtyRecom}
                        bg={(item.recomendedFixtureQty || item.globalQty || item.qty) && item.fixtureRecomendedDescription && (item?.fixtureRecomendedAirtable && customPricing.fixture && isJsonObject(item?.fixtureRecomendedAirtable) ? JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'pending' && JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'qtyChange' && JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'review'  : true) ? '#DBFDCE' : 'transparent'}>
                        {item.recomendedFixtureQty ? item.recomendedFixtureQty : item.globalRecomendedFixtureQty ? item.globalRecomendedFixtureQty : item.qty}
                    </Flex>
                </GridItem>
                <GridItem colSpan={5}>
                    <Flex
                        cursor='pointer'
                        borderColor={item?.fixtureRecomendedAirtable && customPricing.fixture && isJsonObject(item?.fixtureRecomendedAirtable) ? JSON.parse(item.fixtureRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        css={styles.panelDescriptionRecom}
                        bg={item?.fixtureRecomendedDescription && (item?.recomendedFixtureQty || item?.globalQty || item?.qty)
                            && (item?.fixtureRecomendedAirtable && customPricing.fixture && isJsonObject(item?.fixtureRecomendedAirtable) ? JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'pending'
                            && JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'qtyChange'
                            && JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] !== 'review' : true) ? '#DBFDCE' : 'transparent'}>
                        <Text
                            onClick={() => {
                                if (!item?.fixtureRecomendedDescription) return
                                setViewType('recommended')
                                setType('fixture')
                                onOpenLampView()
                            }}
                            maxW='80%'>
                            {item?.fixtureRecomendedDescription ? item.fixtureRecomendedDescription : 'No Change'}
                        </Text>
                        <Button
                            leftIcon={<Edit_Modal width='7px' height='7'/>}
                            css={styles.editButton}
                            onClick={() => {
                                setType('fixture')
                                onOpen()
                            }}>
                            Edit
                        </Button>
                        {
                            item?.fixtureRecomendedAirtable && customPricing.fixture && isJsonObject(item.fixtureRecomendedAirtable) && (
                                <Flex
                                    display={customPricing.fixture ? 'flex' : 'none'}
                                    alignItems='center' justifyContent='center' borderRadius='50%'
                                    bg={JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'review' ? '#FFE68D' : JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'accepted' ? '#8FCF74 !important': '#FF7F56'}
                                    width='18px' height='18px' position='absolute' right='-10px' top='-10px'>
                                    {
                                        item?.fixtureRecomendedAirtable ? JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'review'? (
                                            <PendingIcon />
                                        ) : JSON.parse(item.fixtureRecomendedAirtable)['Bid Status'] === 'accepted' ? (
                                            <DollarIcon />
                                        ) : (
                                            <ReviewIcon />
                                        ) : ''
                                    }
                                </Flex>
                            )
                        }

                    </Flex>
                </GridItem>
                <GridItem colSpan={2} d='flex' alignItems='center'>
                    <Text fontSize="md">Lamp</Text>
                </GridItem>
                <GridItem colSpan={1} d='flex' alignItems='center'>
                    <Flex
                        borderColor={item?.lampRecomendedAirtable && customPricing.lamp && isJsonObject(item?.lampRecomendedAirtable)  ? JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        css={styles.panelQtyRecom}
                        bg={(item?.recomendedLampQty || item?.lampPerFixture) && item?.lampRecomendedDescription && (item?.lampRecomendedAirtable && customPricing.lamp && isJsonObject(item?.lampRecomendedAirtable) ? JSON.parse(item.lampRecomendedAirtable)['Bid Status'] !== 'pending' && JSON.parse(item.lampRecomendedAirtable)['Bid Status'] !== 'qtyChange' && JSON.parse(item.lampRecomendedAirtable)['Bid Status']!=='review' : true) ? '#DBFDCE' : 'white'}>
                        {item.recomendedLampQty ? item.recomendedLampQty : (item?.lampPerFixture ? item?.lampPerFixture : '')}
                    </Flex>
                </GridItem>
                <GridItem colSpan={5} d='flex' alignItems='center'>
                    <Flex
                        cursor='pointer'
                        borderColor={item?.lampRecomendedAirtable && customPricing.lamp && isJsonObject(item?.lampRecomendedAirtable) ? JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        css={styles.panelDescriptionRecom}
                        bg={(item?.recomendedLampQty || item?.lampPerFixture) && item?.lampRecomendedDescription
                        && (item?.lampRecomendedAirtable && customPricing.lamp && isJsonObject(item?.lampRecomendedAirtable) ? JSON.parse(item.lampRecomendedAirtable)['Bid Status'] !== 'pending'  && JSON.parse(item.lampRecomendedAirtable)['Bid Status'] !== 'qtyChange' && JSON.parse(item.lampRecomendedAirtable)['Bid Status']!=='review' : true) ? '#DBFDCE' : 'white'}>
                        <Text
                            onClick={() => {
                                if (!item?.lampRecomendedDescription) return
                                setViewType('recommended')
                                setType('lamp')
                                onOpenLampView()
                            }}
                            maxW='80%'>
                            {item?.lampRecomendedDescription ? item?.lampRecomendedDescription : 'No Change'}
                        </Text>
                        <Button
                            leftIcon={<Edit_Modal width='7px' height='7'/>}
                            css={styles.editButton}
                            onClick={() => {
                                setType('lamp')
                                onOpen()
                            }}>
                            Edit
                        </Button>
                        {
                            item?.lampRecomendedAirtable && isJsonObject(item?.lampRecomendedAirtable) && (
                                <Flex
                                    display={customPricing.lamp ? 'flex' : 'none'}
                                    alignItems='center' justifyContent='center' borderRadius='50%'
                                    bg={JSON.parse(item.lampRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D' : JSON.parse(item.lampRecomendedAirtable)['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                    width='18px' height='18px' position='absolute' right='-10px' top='-10px'>
                                    {
                                        item?.lampRecomendedAirtable ? JSON.parse(item.lampRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.lampRecomendedAirtable)['Bid Status']==='review' ? (
                                            <PendingIcon />
                                        ) : JSON.parse(item.lampRecomendedAirtable)['Bid Status'] === 'accepted' ? (
                                            <DollarIcon />
                                        ) : (
                                            <ReviewIcon />
                                        ) : ''
                                    }
                                </Flex>
                                )
                        }
                    </Flex>
                </GridItem>
                <GridItem colSpan={2} d='flex' alignItems='center'>
                    <Text fontSize="md">Power Supply</Text>
                </GridItem>
                <GridItem colSpan={1} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelQtyRecom}
                        borderColor={item?.recomendedPowerSupply && customPricing.power && isJsonObject(item?.recomendedPowerSupply) ? JSON.parse(item.recomendedPowerSupply)['Bid Status']==='pending' || JSON.parse(item.recomendedPowerSupply)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        bg={item?.powerSupplyRecomendedQty && item?.powerSupplyRecomendedDescription
                        && (item?.recomendedPowerSupply && customPricing.power && isJsonObject(item?.recomendedPowerSupply) ? JSON.parse(item.recomendedPowerSupply)['Bid Status'] !== 'pending'  && JSON.parse(item.recomendedPowerSupply)['Bid Status'] !== 'qtyChange' && JSON.parse(item.recomendedPowerSupply)['Bid Status']!=='review' : true) ? '#DBFDCE' : 'white'}>
                        {item?.powerSupplyRecomendedQty}
                    </Flex>
                </GridItem>
                <GridItem colSpan={5} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelDescriptionRecom}
                        cursor='pointer'
                        borderColor={item?.recomendedPowerSupply && customPricing.power && isJsonObject(item?.recomendedPowerSupply) ? JSON.parse(item.recomendedPowerSupply)['Bid Status']==='pending' || JSON.parse(item.recomendedPowerSupply)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        bg={item?.powerSupplyRecomendedDescription && item?.powerSupplyRecomendedQty
                        && (item?.recomendedPowerSupply && customPricing.power && isJsonObject(item?.recomendedPowerSupply) ? JSON.parse(item.recomendedPowerSupply)['Bid Status'] !== 'pending' && JSON.parse(item.recomendedPowerSupply)['Bid Status'] !== 'qtyChange' && JSON.parse(item.recomendedPowerSupply)['Bid Status']!=='review'  && JSON.parse(item.recomendedPowerSupply)['Bid Status']!=='qtyChange' : true) ? '#DBFDCE' : 'white'}>
                        <Text
                            onClick={() => {
                                if (!item?.powerSupplyRecomendedDescription) return
                                setViewType('recommended')
                                setType('power')
                                onOpenAccessoryView()
                            }}
                            maxW='80%'>
                            {item?.powerSupplyRecomendedDescription ? item?.powerSupplyRecomendedDescription : 'No Change'}
                        </Text>
                        <Button
                            leftIcon={<Edit_Modal width='7px' height='7'/>}
                            css={styles.editButton}
                            onClick={() => {
                                setType('power')
                                onOpen()
                            }}>
                            Edit
                        </Button>
                        {
                            item?.recomendedPowerSupply && customPricing.power && isJsonObject(item?.recomendedPowerSupply) && (
                                <Flex
                                    display={customPricing.power ? 'flex' : 'none'}
                                    alignItems='center' justifyContent='center' borderRadius='50%'
                                    bg={JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'pending' || JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'review' ? '#FFE68D' : JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                    width='18px' height='18px' position='absolute' right='-10px' top='-10px'>
                                    {
                                        item?.recomendedPowerSupply?.length > 0 ? JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'pending' || JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'review' ? (
                                            <PendingIcon />
                                        ) : JSON.parse(item.recomendedPowerSupply)['Bid Status'] === 'accepted' ? (
                                            <DollarIcon />
                                        ) : (
                                            <ReviewIcon />
                                        ) : ''
                                    }
                                </Flex>
                            )
                        }
                    </Flex>
                </GridItem>
                <GridItem colSpan={2} d='flex' alignItems='center'>
                    <Text fontSize="md">Control</Text>
                </GridItem>
                <GridItem colSpan={1} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelQtyRecom}
                        borderColor={item?.controlRecomendedAirtable && customPricing.control && isJsonObject(item?.controlRecomendedAirtable) ? JSON.parse(item.controlRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.controlRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        bg={item?.controlRecomendedDescription && item?.controlRecomendedQty
                        && (item?.controlRecomendedAirtable && customPricing.control && isJsonObject(item?.controlRecomendedAirtable) ? JSON.parse(item.controlRecomendedAirtable)['Bid Status'] !== 'pending' && JSON.parse(item.controlRecomendedAirtable)['Bid Status'] !== 'qtyChange' && JSON.parse(item.controlRecomendedAirtable)['Bid Status']!=='review' : true) ? '#DBFDCE' : 'white'}>
                        {item?.controlRecomendedQty}
                    </Flex>
                </GridItem>
                <GridItem colSpan={5} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelDescriptionRecom}
                        cursor='pointer'
                        borderColor={item?.controlRecomendedAirtable && customPricing.control && isJsonObject(item?.controlRecomendedAirtable) ? JSON.parse(item.controlRecomendedAirtable)['Bid Status']==='pending' || JSON.parse(item.controlRecomendedAirtable)['Bid Status']==='review' ? '#FFE68D !important'  : '#FFE68D' : ''}
                        bg={item?.controlRecomendedDescription  && item?.controlRecomendedQty
                        && (item?.controlRecomendedAirtable && customPricing.control && isJsonObject(item?.controlRecomendedAirtable) ? JSON.parse(item.controlRecomendedAirtable)['Bid Status'] !== 'pending' && JSON.parse(item.controlRecomendedAirtable)['Bid Status'] !== 'qtyChange' && JSON.parse(item.controlRecomendedAirtable)['Bid Status']!=='review' : true) ? '#DBFDCE' : 'white'}>
                        <Text
                            onClick={() => {
                                if (!item?.controlRecomended) return
                                setViewType('recommended')
                                setType('control')
                                onOpenAccessoryView()
                            }}
                            maxW='80%'>
                            {item?.controlRecomendedDescription ? item?.controlRecomendedDescription : 'No Change'}
                        </Text>
                        <Button
                            leftIcon={<Edit_Modal width='7px' height='7'/>}
                            css={styles.editButton}
                            onClick={() => {
                                setType('control')
                                onOpen()
                            }}>
                            Edit
                        </Button>
                        {
                            item?.controlRecomendedAirtable && customPricing.control && isJsonObject(item?.controlRecomendedAirtable) && (
                                <Flex
                                    display={customPricing.control ? 'flex' : 'none'}
                                    alignItems='center' justifyContent='center' borderRadius='50%'
                                    bg={JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'review' ? '#FFE68D' : JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'accepted' ? '#8FCF74': '#FF7F56'}
                                    width='18px' height='18px' position='absolute' right='-10px' top='-10px'>
                                    {
                                        item?.controlRecomendedAirtable && isJsonObject(item?.controlRecomendedAirtable) ? JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'pending' || JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'review' ? (
                                            <PendingIcon />
                                        ) : JSON.parse(item.controlRecomendedAirtable)['Bid Status'] === 'accepted' ? (
                                            <DollarIcon />
                                        ) : (
                                            <ReviewIcon />
                                        ) : ''
                                    }
                                </Flex>
                            )
                        }
                    </Flex>
                </GridItem>
                <GridItem colSpan={2} d='flex' alignItems='center'>
                    <Text fontSize="md">Accessory</Text>
                </GridItem>
                <GridItem colSpan={1} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelQtyRecom}
                        bg={item?.accessoryRecomendedDescription && item?.accessoryRecomendedQty ? '#DBFDCE' : 'white'}>
                        {item?.accessoryRecomendedQty}
                    </Flex>
                </GridItem>
                <GridItem colSpan={5} d='flex' alignItems='center'>
                    <Flex
                        css={styles.panelDescriptionRecom}
                        bg={item?.accessoryRecomendedDescription && item?.accessoryRecomendedQty ? '#DBFDCE' : 'white'}>
                        <Text
                            maxW='80%'
                            cursor='pointer'
                            onClick={() => {
                                if (!item?.accessoryRecomendedDescription) return
                                setViewType('recommended')
                                setType('accessories')
                                onOpenAccessoryView()
                            }}
                        >
                            {item?.accessoryRecomendedDescription ? item?.accessoryRecomendedDescription : 'No Change'}
                        </Text>
                        <Button
                            leftIcon={<Edit_Modal width='7px' height='7'/>}
                            css={styles.editButton}
                            onClick={() => {
                                setType('accessories')
                                onOpen()
                            }}>
                            Edit
                        </Button>
                    </Flex>
                </GridItem>
            </Grid>
        </GridItem>
    </GridItem>
)

export default RecommendedLampGrid