import {Box, Flex, Heading, Button, Text, Input, Divider, Image, GridItem, Grid} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import {ReactComponent as SignatureIcon} from "../../../assets/signatureIcon.svg";
import {ReactComponent as AuthIcon} from "../../../assets/okIcon.svg";
import {ReactComponent as DoneIcon} from "../../../assets/doneIcon.svg";
import Luminaires from "../parts/Luminaires";
import Loading from "../../../components/Loading";

const OrderSuTab = ({
                        refererOrder,
                        currentDate,
                        estimate,
                        luminaires,
                        subTotal,
                        notes,
                        salesTax,
                        shipping,
                        total,
                        user,
                        loading,
                        authorize,
                        onOpenSignatureAdmin,
                        imageUrlAdmin,
                        isLoading,
                    }) => {
    if (isLoading) return <Loading/>

    return (
        <Box ref={refererOrder} display={estimate?.orderDateCreated ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex
                justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex
                    flexDirection='column'>
                    <Heading
                        m={0}
                        textAlign='end'>
                        Order
                    </Heading>
                    <Text>
                        Reference # : {estimate?.reference}
                    </Text>
                    <Text
                        textAlign='end'>
                        Date : {moment(estimate?.orderDateCreated?.date).format('MM/DD/YYYY')}
                    </Text>
                </Flex>
            </Flex>
            <Flex
                mt={4}
                fontSize='18px'
                justifyContent='space-between'>
                <Flex
                    width='49%'
                    borderRight='2px solid #9C9C9C'
                    flexDirection='column'>
                    <Input
                        mb={3}
                        readOnly
                        border='none'
                        backgroundColor='#EEEEEE'
                        width='98%'
                        fontFamily='Titillium Web'
                        fontSize='18px'
                        value='Billing Information:'
                        color='#222222'
                        fontWeight='700'/>
                    <Text>
                        Focus Earth
                    </Text>
                    <Text>
                        Attn: Pete Musser
                    </Text>
                    <Text>
                        1070 Tunnel Road
                    </Text>
                    <Text>
                        Building 1, Suite 10, #202
                    </Text>
                    <Text>
                        Asheville, NC 28805
                    </Text>
                    <Text>
                        USA
                    </Text>
                    <Text>
                        (925) 323-0053
                    </Text>
                </Flex>
                <Flex
                    width='50%'
                    flexDirection='column'>
                    <Input
                        mb={3}
                        readOnly
                        border='none'
                        fontFamily='Titillium Web'
                        backgroundColor='#EEEEEE'
                        width='100%'
                        fontSize='18px'
                        value='Shipping Information:'
                        color='#222222'
                        fontWeight='700'/>
                    <Text>
                        {estimate?.userReu?.company[0]?.name}
                    </Text>
                    <Text>
                        Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}
                    </Text>
                    <Text>
                        {estimate?.report?.project?.address}
                    </Text>
                    <Text>
                        {estimate?.report?.project?.address2}
                    </Text>
                    <Text>
                        {estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}
                    </Text>
                    <Text>
                        USA
                    </Text>
                    <Text>
                        {estimate?.userReu?.phone}
                    </Text>
                </Flex>
            </Flex>
            <Input
                readOnly
                border='none'
                backgroundColor='#8FCF74'
                width='100%'
                fontSize='18px'
                fontFamily='Titillium Web'
                fontWeight='700'
                color='#222222'
                value='Order Information'
                mt={10}/>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex
                justifyContent='end'
                mt={7}>
                <Flex
                    width='50%'
                    fontWeight='700'
                    border='2px solid #8FCF74'
                    height='64px'
                    alignItems='center'
                    justifyContent='space-between'
                    pl={6}
                    pr={2}
                    borderRadius='6px'>
                    <Input
                        readOnly
                        fontWeight='600'
                        border='none'
                        fontFamily='Titillium Web'
                        value='Sub Total:'
                        fontSize='16px'/>
                    <Flex
                        justifyContent='end'>
                        <Input
                            readOnly
                            border='none'
                            fontSize='14px'
                            fontWeight='600'
                            value={subTotal ? '$' + subTotal?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                fontSize='18px !important'
                justifyContent='space-between'>
                <Flex
                    width='50%'
                    flexDirection='column'>
                    <Text
                        color='#2C628D'
                        mb={2}
                        fontWeight='700'>
                        Notes:
                    </Text>
                    <Textarea
                        readOnly
                        value={notes}
                        minH='130px'/>
                </Flex>
                <Flex
                    flexDirection='column'
                    color='#2C628D'
                    mt={7}>
                    <Flex
                        alignItems='center'>
                        <Text>
                            Sales Tax:
                        </Text>
                        <InputLabel
                            readOnly
                            value={salesTax ? '$' + Number(salesTax)?.toLocaleString('en-US') : '$'}
                            ml={5}
                            maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center'>
                        <Text>
                            Shipping:
                        </Text>
                        <InputLabel
                            readOnly
                            value={shipping ? '$' + Number(shipping)?.toLocaleString('en-US') : '$'}
                            ml={5}
                            maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center'>
                        <Text ml={7} fontWeight='700'>
                            Total:
                        </Text>
                        <InputLabel
                            readOnly
                            value={'$' + total?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            ml={5}
                            style={{'border': '2px solid #8FCF74'}}
                            maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                justifyContent='space-between'
                alignItems='center'
                mt={10}>
                <Divider
                    borderColor="#222222"
                    border="2px solid"
                    width='35%'
                    orientation="horizontal"/>
                <Text
                    width='max-content'>
                    FOCUS EARTH AUTHORIZATION
                </Text>
                <Divider
                    width='35%'
                    borderColor="#222222"
                    border="2px solid"
                    orientation="horizontal"/>
            </Flex>
            <Flex
                justifyContent='space-between'
                mt={10}
                fontWeight='700'>
                <Flex
                    width='33%'
                    alignItems='center'>
                    <Input
                        border='none'
                        readOnly
                        fontSize='16px'
                        fontWeight='600'
                        width='120px'
                        value='Print Name:'
                        color='#2C628D'/>
                    <Input
                        readOnly
                        value={estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName}
                        fontWeight='700'
                        style={{'width': '174px', 'height': '32px'}}/>
                </Flex>
                <Flex
                    alignItems='center'
                    width='33%'
                    justifyContent='center'
                    fontWeight='700'>
                    <Input
                        border='none'
                        readOnly
                        fontSize='16px'
                        fontWeight='600'
                        width='120px'
                        value='Signature:'
                        color='#2C628D'/>
                    <Flex
                        pr={2}
                        pl={2}
                        width='207px'
                        alignItems='center'
                        height='32px'
                        justifyContent='space-between'
                        borderRadius='6px'
                        border='1px solid #A9A9A9'>
                        <Text
                            height='100%'
                            width='100%'>
                            {
                                imageUrlAdmin ? (
                                    <Image
                                        objectFit='contain'
                                        height='100%'
                                        alt="Electronic signature"
                                        width='100%'
                                        src={imageUrlAdmin}/>
                                ) : (
                                    ''
                                )
                            }
                        </Text>
                        <Text
                            onClick={estimate?.adminSignature || user?.type === 'Su' ? '' : onOpenSignatureAdmin}
                            as='span'
                            cursor='pointer'>
                            <SignatureIcon/>
                        </Text>
                    </Flex>
                </Flex>
                <Flex
                    alignItems='center'
                    width='33%'
                    justifyContent='end'>
                    <Input
                        border='none'
                        readOnly
                        fontSize='16px'
                        fontWeight='600'
                        width='80px'
                        value='Date:'
                        color='#2C628D'/>
                    <Input
                        fontWeight='700'
                        readOnly
                        value={estimate?.adminSignatureDate ? estimate.adminSignatureDate : currentDate}
                        style={{'width': '174px', 'height': '32px'}}/>
                </Flex>
            </Flex>
            <Flex
                display={user?.type === 'SUPERADMIN' || user?.type === 'Sa' || user?.type === 'Au' ? 'flex' : 'none'}
                mt={4}
                justifyContent='end'>
                <Button
                    isLoading={loading}
                    onClick={authorize}
                    fontSize='14px'
                    leftIcon={estimate?.adminSignature ? <DoneIcon/> : <AuthIcon/>}
                    width='170px'
                    color={estimate?.adminSignature ? 'black' : 'white'}
                    backgroundColor={estimate?.adminSignature ? '#80CC5F' : '#246B06'}>
                    <Input
                        p={0}
                        width='70px'
                        fontSize='14px'
                        cursor='pointer'
                        readOnly
                        border='none'
                        value={estimate?.adminSignature ? 'Authorized!' : 'Authorize'}/>
                </Button>
            </Flex>
        </Box>
    )
}

export default OrderSuTab