import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Box, Text, Button, useDisclosure, useToast} from '@chakra-ui/react'
import TableWrapper from './TableWrapper'
import Accordion from './Accordion'
import Navbar from '../Navbar'
import {useCreateReport, useGetProject, useSignOut} from '../../core/hooks'
import {ReactComponent as Unfinished} from "../../assets/unfinishedReport.svg";
import Area from "./Area";
import BiddingOpenAcceptModal from "./ReportModals/BiddingOpenAcceptModal";
import BiddingCloseModal from "./ReportModals/BiddingCloseModal";
import InstallationBidSelectModal from "./ReportModals/InstallationBidSelectModal";
import {ReactComponent as SendMessage} from "../../assets/unfinishedIcon.svg";
import httpRequest from "../../core/services/http-request";
import BiddingDone from "../OverviewReport/ReportModals/BiddingDone";
import ExitReportModal from "./ReportModals/ExitReportModal";
import ToastComponent from "../toastComponent/ToastComponent";
import Loading from "../Loading";
import {ReactComponent as DoneIcon} from "../../assets/Icons/DoneIcon.svg";
import mainStyles from "../../styles/mainStyles";
import Header from "./Parts/Header";
import DeleteReportModal from "../OverviewReport/ReportModals/DeleteReportModal";

function Overview() {
    const toast = useToast()
    const params = useParams()
    const history = useHistory()
    const {post} = httpRequest()
    const {signOut} = useSignOut()
    const [recent, setRecent] = useState([])
    const [errors, setErrors] = useState([])
    const [vale, setVale] = useState([])
    const [area, setArea] = useState([])
    const [project, setProject] = useState([])
    const [selectedBid, setSelectedBid] = useState([])
    const [loading, setLoading] = useState(false)
    const [customPricing, setCustomPricing] = useState({
        fixture: false,
        lamp: false,
        control: false,
        power: false,
        accessory: false,
    })
    const [leds, setLeds] = useState([])
    const [areaTitle, setAreaTitle] = useState([])
    const [route, setRoute] = useState('')
    const [recentProposal, setRecentProposal] = useState([])
    const [report, setReport] = useState({proposal: '', status: '', installationEstimate: '', notes: '', areas: []})
    const {isOpen: isOpenBiddingClose, onOpen: onOpenBiddingClose, onClose: onCloseBiddingClose} = useDisclosure()
    const {isOpen: isOpenBiddingAccept, onOpen: onOpenBiddingAccept, onClose: onCloseBiddingAccept} = useDisclosure()
    const {isOpen: isOpenInstallation, onOpen: onOpenInstallation, onClose: onCloseInstallation} = useDisclosure()
    const {isOpen: isOpenDone, onOpen: onOpenDone, onClose: onCloseDone} = useDisclosure()
    const {isOpen: isOpenExit, onOpen: onOpenExit, onClose: onCloseExit} = useDisclosure()
    const {isLoading: isProjectLoading, data: projectResponse, refetch: refetchProject} = useGetProject(params.id)
    const {isLoading: isReportLoading, createReport} = useCreateReport()
    const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure()

    const handelSubmit = () => {
        //Unfinished
        if (!area) return
        let can = false
        for (let i = 0; i < area.length; i++) {
            for (let j = 0; j < area[i].luminaires.length; j++) {
                if (area[i].luminaires[j].lampRecomendedAirtable || area[i].luminaires[j].fixtureRecomendedDescription
                    || area[i].luminaires[j].powerSupplyRecomendedDescription || area[i].luminaires[j].controlRecomendedDescription || area[i].luminaires[j].accessoryRecomendedDescription) {
                    can = true
                    break
                }
            }
        }
        if (!can) return setErrors('Attention: Report will not generate without recommendations! Make some to mark this report as Unfinished')
        setErrors('')
        const input = {
            projectId: project.id,
            customPricing: customPricing,
            proposalName: report.proposal ? report.proposal : '',
            proposal: area.filter((filtered) => vale.includes(filtered.title)),
            installation: selectedBid ? selectedBid : '',
            notes: report?.notes ? report?.notes : '',
        }
        if (!input['proposalName']) return setErrors('Write report name')
        setLoading(true)
        setErrors('')
        createReport(input, {
            onSuccess: (data) => {
                setLoading(false)
                toast({
                    render: () => (
                        <ToastComponent icon={<SendMessage/>} title={`${report.proposal} marked as unfinished...`} bg='#FCA68B'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                history.push(`/projects/proposal/${data.data.data.proposalName}/${params.id}/${data.data.data.id}`)
                window.location.reload()
            },
        })
    }

    useEffect(() => {
        if (!projectResponse) return
        let data = projectResponse.data.data[0]
        setProject(data.project)
        const areas = data.project.areas
        const newArray = []
        for (let i = 0; i < areas.length; i++) {
            newArray.push(areas[i].title)
        }
        setAreaTitle(newArray)
        setArea(areas)
        if (data?.project?.report) return
        if (parseInt(params.id) === data?.project?.id) return
        const prop = data?.project?.report?.filter((item) => item !== params.id)
        setRecentProposal(prop[0])
    }, [projectResponse])

    const deleteReport = () => {
        onCloseDelete()
        history.push(`/project/${params.id}`)
        window.location.reload()
    }

    const reportDone = () => {
        if (!area) return
        let can = false
        for (let i = 0; i < area.length; i++) {
            for (let j = 0; j < area[i].luminaires.length; j++) {
                if (area[i].luminaires[j].lampRecomendedAirtable || area[i].luminaires[j].fixtureRecomendedDescription
                    || area[i].luminaires[j].powerSupplyRecomendedDescription || area[i].luminaires[j].controlRecomendedDescription || area[i].luminaires[j].accessoryRecomendedDescription) {
                    can = true
                    break
                }
            }
        }
        if (!can) {
            return setErrors('Attention: Report will not generate without recommendations! Make some to mark this report as Unfinished')
        }
        setErrors('')
        if (!selectedBid) {
            return setErrors('Choose bid')
        }
        const input = {
            projectId: project.id,
            customPricing: customPricing,
            proposalName: report.proposal ? report.proposal : '',
            proposal: area ? area.filter((filtered) => vale.includes(filtered.title)) : '',
            installation: selectedBid ? selectedBid : '',
            notes: report?.notes ? report?.notes : '',
        }
        if (!input['proposalName']) {
            return setErrors('Write report name')
        }
        setLoading(true)
        createReport(
            input,
            {
                onSuccess: (e) => {
                    const input = {
                        projectId: project.id,
                        customPricing: customPricing,
                        proposalName: report.proposal !== '' ? report.proposal : recentProposal.proposalName,
                        proposal: area ? area : '',
                        installation: selectedBid ? selectedBid : '',
                        recentProposal: e.data.data?.id,
                        notes: report?.notes ? report?.notes : '',
                    }
                    post("report-done", {input}).then(() => {
                        setLoading(false)
                        onOpenDone()
                        setTimeout(() => {
                            history.push(`/projects/proposal/${e.data.data.proposalName}/${params.id}/${e.data.data.id}`)
                            window.location.reload()
                        }, 2000)
                    }).catch(() => setLoading(false))
                },
            },
        )
    }

    const exitFunc = (name) => {
        if (name === 'signout') {
            signOut()
        } else {
            setRoute(name)
        }
        onOpenExit()
    }


    useEffect(() => {
        if (!area || area?.length === 0) return
        const groupedObjects = new Map();
        for (let i = 0; i < area?.length; i++) {
            if (vale.includes(area[i].title)) {
                for (let j = 0; j < area[i].luminaires?.length; j++) {
                    let {id, qty,recomendedFixtureQty, fixtureMountHeight, ...rest} = area[i].luminaires[j];
                    let luminWithoutIdQty = JSON.stringify(rest);
                    if (groupedObjects.has(luminWithoutIdQty)) {
                        groupedObjects.get(luminWithoutIdQty).globalQty += Number(qty);
                        groupedObjects.get(luminWithoutIdQty).globalRecomendedFixtureQty += Number(recomendedFixtureQty);
                        groupedObjects.get(luminWithoutIdQty).globalQtyLamp += Number(area[i].luminaires[j]?.lampPerFixture);
                    } else {
                        groupedObjects.set(luminWithoutIdQty, {
                            ...JSON.parse(luminWithoutIdQty),
                            globalQty: Number(qty),
                            globalRecomendedFixtureQty : Number(recomendedFixtureQty),
                            globalQtyLamp: Number(area[i].luminaires[j]?.lampPerFixture),
                        });
                    }
                }
            }
        }
        setLeds([...Array.from(groupedObjects.values())]);
    }, [area,vale])

    if (isProjectLoading) return <Loading/>

    return (
        <Box d="flex" flexDirection="column" alignItems="flex-end" minH="100vh" bg="white.100" minW="900px" overflowX="hidden">
            <BiddingDone
                isOpen={isOpenDone}
                onClose={onCloseDone}/>
            <ExitReportModal
                route={route}
                unfinished={handelSubmit}
                onClose={onCloseExit}
                isOpen={isOpenExit}/>
            <InstallationBidSelectModal
                setSelectedBid={setSelectedBid}
                project={project}
                refetch={refetchProject}
                isOpen={isOpenInstallation}
                onClose={onCloseInstallation}/>
            <BiddingCloseModal
                refetch={refetchProject}
                id={project?.id}
                isOpen={isOpenBiddingClose}
                onClose={onCloseBiddingClose}/>
            <BiddingOpenAcceptModal
                customPricing={customPricing}
                refetch={refetchProject}
                selectedBid={selectedBid}
                project={project}
                area={area}
                report={report}
                recentProposal={recentProposal}
                isOpen={isOpenBiddingAccept}
                onClose={onCloseBiddingAccept}/>
            <DeleteReportModal
                history={history}
                loading={false}
                deleteReport={deleteReport}
                onClose={onCloseDelete}
                isOpen={isOpenDelete}/>
            <Navbar title="Report" open={exitFunc}>
                <Button
                    color='black'
                    isLoading={loading}
                    onClick={handelSubmit}
                    leftIcon={<Unfinished/>}
                    backgroundColor={Object.values(customPricing).some(value => value === true) ? '#FFE68D' : '#FFA183'}
                    width="135px"
                    mr='20px'>
                    {Object.values(customPricing).some(value => value === true) ? 'Request Pricing ' : 'Unfinished'}
                </Button>
                <Button
                    color='black'
                    width="135px"
                    disabled={Object.values(customPricing).some(value => value === true)}
                    isLoading={loading}
                    onClick={reportDone}
                    leftIcon={<DoneIcon/>}
                    backgroundColor={Object.values(customPricing).some(value => value === true) ? '#A9A9A9' : '#C2EF62'}>
                    Done
                </Button>
            </Navbar>
            <Box css={mainStyles.container} px='40px' pt='20px'>
                {errors && (<Text textAlign='center' color="red.100" fontSize="m">{errors}</Text>)}
                <Header
                    edit={false}
                    areaTitle={areaTitle}
                    onOpenBiddingAccept={onOpenBiddingAccept}
                    onOpenBiddingClose={onOpenBiddingClose}
                    recentProposal={recentProposal}
                    setVale={setVale}
                    vale={vale}
                    loading={loading}
                    report={report}
                    onOpenDelete={onOpenDelete}
                    onOpenInstallation={onOpenInstallation}
                    selectedBid={selectedBid}
                    setReport={setReport}/>
                <TableWrapper>
                    <Accordion
                        leds={leds}
                        key={report?.id}
                        reportId={report?.id}
                        customPricing={customPricing}
                        setCustomPricing={setCustomPricing}
                        setRecent={setRecent}
                        recent={recent}
                        area={area}
                        vale={vale}
                        setArea={setArea}
                        project={project}/>
                    <Box>
                        {vale && (
                            project?.areas?.filter((item) => vale.includes(item.title)).map((item, idx) => (
                                <Area
                                    key={idx}
                                    reportId={report?.id}
                                    customPricing={customPricing}
                                    setCustomPricing={setCustomPricing}
                                    project={project}
                                    area={area}
                                    setArea={setArea}
                                    idx={idx}
                                    setRecent={setRecent}
                                    recent={recent}
                                    data={item}
                                    areaName={item.title}
                                    areas={item}/>)))}
                    </Box>
                </TableWrapper>
            </Box>
        </Box>
    )
}

export default Overview
