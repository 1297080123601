import styles from "./styles";
import {Grid,GridItem, Heading, Text, Flex} from '@chakra-ui/react'

const ExistingLampGrid = ({item, onOpenLampView, setType, setViewType, onOpenAccessoryView}) => (
    <GridItem border='1px solid' borderColor="#C4C4C4" bg='white'>
        <Grid templateColumns='repeat(8, 1fr)' p={4} columnGap={6} rowGap={2}>
            <GridItem colSpan={2}>
                <Heading color="grey.200" fontSize="md">COMPONENT</Heading>
            </GridItem>
            <GridItem colSpan={1}>
                <Heading color="grey.200" fontSize="md">QTY</Heading>
            </GridItem>
            <GridItem colSpan={5}>
                <Heading color="grey.200" fontSize="md">DESCRIPTION</Heading>
            </GridItem>
            <GridItem colSpan={2} d='flex' alignItems='center'>
                <Text fontSize="md">Fixture</Text>
            </GridItem>
            <GridItem colSpan={1} d='flex' alignItems='center'>
                <Flex
                    css={styles.panelQty}
                    bg={item?.qty !== 0 || item?.globalQty !== 0 ? '#F5F5F5' : 'white'}>
                    {item.globalQty ? item.globalQty : item.qty}
                </Flex>
            </GridItem>
            <GridItem colSpan={5}>
                <Flex
                    cursor='pointer'
                    onClick={() => {
                        if (!item?.fixtureDescription) return
                        setViewType('existing')
                        setType('fixture')
                        onOpenLampView()
                    }}
                    css={styles.panelDescription}
                    bg={item?.fixtureDescription ? '#F5F5F5' : 'white'}>
                    {item?.fixtureDescription}
                </Flex>
            </GridItem>
            <GridItem colSpan={2} d='flex' alignItems='center'>
                <Text fontSize="md">Lamp</Text>
            </GridItem>
            <GridItem colSpan={1} d='flex' alignItems='center'>
                <Flex
                    css={styles.panelQty}
                    bg={item?.lampPerFixture !== 0 || item?.globalQtyLamp !== 0 ? '#F5F5F5' : 'white'}>
                    {item.globalQtyLamp ? item.globalQtyLamp : item?.lampPerFixture}
                </Flex>
            </GridItem>
            <GridItem colSpan={5} d='flex' alignItems='center'>
                <Flex
                    cursor='pointer'
                    onClick={() => {
                        if (!item?.lampDescription) return
                        setViewType('existing')
                        setType('lamp')
                        onOpenLampView()
                    }}
                    css={styles.panelDescription}
                    bg={item?.lampDescription ? '#F5F5F5' : 'white'}>
                    {item?.lampDescription}
                </Flex>
            </GridItem>
            <GridItem colSpan={2} d='flex' alignItems='center'>
                <Text fontSize="md">Power Supply</Text>
            </GridItem>
            <GridItem colSpan={1} d='flex' alignItems='center'>
                <Flex
                    css={styles.panelQty}
                    bg={item?.powerQTYFixture ? '#F5F5F5' : 'white'}>
                    {item?.powerQTYFixture}
                </Flex>
            </GridItem>
            <GridItem colSpan={5} d='flex' alignItems='center'>
                <Flex
                    cursor='pointer'
                    onClick={() => {
                        if (!item?.powerSupplyDescription) return
                        setViewType('existing')
                        setType('power')
                        onOpenAccessoryView()
                    }}
                    css={styles.panelDescription}
                    bg={item?.powerSupplyDescription ? '#F5F5F5' : 'white'}>
                    {item?.powerSupplyDescription}
                </Flex>
            </GridItem>
            <GridItem colSpan={2} d='flex' alignItems='center'>
                <Text fontSize="md">Control</Text>
            </GridItem>
            <GridItem colSpan={1} d='flex' alignItems='center'>
                <Flex
                    css={styles.panelQty}
                    bg={item?.controlQTYFixture ? '#F5F5F5' : 'white'}>
                    {item?.controlQTYFixture}
                </Flex>
            </GridItem>
            <GridItem colSpan={5} d='flex' alignItems='center'>
                <Flex
                    cursor='pointer'
                    onClick={() => {
                        if (!item?.controlDescription) return
                        setViewType('existing')
                        setType('control')
                        onOpenAccessoryView()
                    }}
                    css={styles.panelDescription}
                    bg={item?.controlDescription ? '#F5F5F5' : 'white'}>
                    {item?.controlDescription}
                </Flex>
            </GridItem>
            <GridItem colSpan={2} d='flex' alignItems='center'>
                <Text fontSize="md">Accessory</Text>
            </GridItem>
            <GridItem colSpan={1} d='flex' alignItems='center'>
                <Flex
                    css={styles.panelQty}
                    bg={item?.accessoryDescriptionAQty ? '#F5F5F5' : 'white'}>
                    {
                        item?.accessoryDescriptionAQty && (
                            (item?.accessoryDescriptionAQty ? Number(item?.accessoryDescriptionAQty) : 0)
                            + (item?.accessoryDescriptionBQty ? Number(item?.accessoryDescriptionBQty) : 0)
                            + (item?.accessoryDescriptionCQty ? Number(item?.accessoryDescriptionCQty) : 0)
                        )
                      }
                </Flex>
            </GridItem>
            <GridItem colSpan={5} d='flex' alignItems='center'>
                <Flex
                    cursor='pointer'
                    onClick={() => {
                        if (!item?.accessoryDescriptionA) return
                        setViewType('existing')
                        setType('accessories')
                        onOpenAccessoryView()
                    }}
                    css={styles.panelDescription}
                    bg={item?.accessoryDescriptionA ? '#F5F5F5' : 'white'}>
                    {
                        item?.accessoryDescriptionA && (
                            item?.accessoryDescriptionA + (item?.accessoryDescriptionB && ', '+item?.accessoryDescriptionB)+ (item?.accessoryDescriptionC && ', '+item?.accessoryDescriptionC)
                        )
                        }
                </Flex>
            </GridItem>
        </Grid>
    </GridItem>
)

export default ExistingLampGrid