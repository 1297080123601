import {Stack, HStack, Box, Input, Select, Heading, Checkbox, Flex, Text} from '@chakra-ui/react'
import {InputLabel} from '../../../../components/Theme'
import NavigateModal from './NavigateModal'
import Loading from "../../../Loading";
import {useGetCustomBids, useGetRecommended} from "../../../../core/hooks";
import {FIXTURE_FILTER, FIXTURE_FILTER_MIN, PRODUCT_TYPES} from "../../../../constants/types";
import React, {useState, useEffect} from "react";
import CustomSelectMultiple from "../../Parts/CustomSelectMultiple";
import {SU} from "../../../../constants/user/userEnum";
import {ReactComponent as DollarIcon} from "../../../../assets/Icons/dollarIcon.svg";
import {ReactComponent as PendingIcon} from "../../../../assets/Icons/pendingIcon.svg";
import {ReactComponent as ArrowDownIcon} from "../../../../assets/downArrow.svg";
import styles from "../styles";

const Fixture = ({
                     fixture,
                     reportId,
                     customPricing,
                     setCustomPricing,
                     fromAll,
                     setFixture,
                     project,
                     luminaire,
                     type,
                     description,
                     onClose,
                     createKey,
                     setCreateKey,
                     setRecent,
                     area,
                     setArea,
                     luminaireId,
                     setType,
                 }) => {
    const [filters, setFilters] = useState({
        wattageMin: '',
        wattageMax: '',
        colorMin: '',
        colorMax: '',
        lumensMin: '',
        lumensMax: '',
        priceMin: '',
        priceMax: '',
        dimmability: '',
    })
    const [fetchedDataFixture, setFetchedDataFixture] = useState([])
    const [certifications, setCertifications] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const {data, isLoading, refetch} = useGetRecommended(
        {
            condition: {
                table: PRODUCT_TYPES["1"],
                filter: FIXTURE_FILTER(luminaire),
                filterMin: FIXTURE_FILTER_MIN(luminaire),
                projectId: project?.id,
            }
        }
    )
    const {data: dataCustomBids, isLoading: isLoadingBids, refetch: refetchBids} = useGetCustomBids(
        {
            condition: {
                type: 'report',
                projectId: project?.id,
                productType: 'fixture',
                reportId: reportId,
                userType: SU,
            }
        }
    )
    const [selectAll, setSelectAll] = useState(false);
    const [fixtureAirtable, setFixtureAirtable] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [customBids, setCustomBids] = useState([]);
    const [buyAmerican, setBuyAmerican] = useState(false);

    const handleSelect = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fetchedDataFixture?.filter((item) => validate(item) === true).map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (type === 'fixture') {
            refetch()
        }
    }, [type])

    const validate = ({fields}) => {
        let bool = true
        if (buyAmerican && bool){
            !!fields['Buy America'] ? bool = fields['Buy America'] === 'Yes' : bool = false
        }
        if (!!filters.wattageMin && !!filters.wattageMax && bool && fields['Wattage']) {
            !!fields['Wattage'] ? bool = parseInt(filters.wattageMin) <= parseInt(fields['Wattage']) && parseInt(filters.wattageMax) >= parseInt(fields['Wattage']) : bool = false
        }
        if (!!filters.colorMin && !!filters.colorMax && bool && fields['CCT (K˚)']) {
            let cctValues = fields['CCT (K˚)'].match(/\d+/g).map(Number);
            let colorMin = parseInt(filters.colorMin);
            let colorMax = parseInt(filters.colorMax);

            bool = cctValues.some(cctValue => colorMin <= cctValue && cctValue <= colorMax);
        }
        if (!!filters.priceMin && !!filters.priceMax && bool && fields['Price Per Unit ($)']) {
            !!fields['Price Per Unit ($)'] ? bool = parseInt(filters.priceMin) < parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) && parseInt(filters.priceMax) > parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) : bool = false
        }
        if (!!filters.lumensMin && !!filters.lumensMax && bool && fields['Lumens']) {
            !!fields['Lumens'] ? bool = parseInt(filters.lumensMin) < parseInt(fields['Lumens']) && parseInt(filters.lumensMax) > parseInt(fields['Lumens']) : bool = false
        }
        if (!!filters.dimmability && bool) {
            !!fields['Dimmable'] ? bool = fields['Dimmable'].includes(filters.dimmability) : bool = false
        }
        if (certifications.length > 0){
            !!fields['Certifications'] ? bool = certifications.includes(fields['Certifications']) : bool = false
        }
        if (customPricing.fixture && bool) {
            if (!fields.hasOwnProperty('Price Per Unit ($)')){
                bool = true
            } else if (fields['Price Per Unit ($)'] && Number(fields['Price Per Unit ($)']?.toString()?.replace(/[^\d.]/g, '')) === 0){
                bool = true
            } else {
                bool = false
            }
        }
        if (!customPricing.fixture && bool){
            if (!fields.hasOwnProperty('Price Per Unit ($)') && !fields.hasOwnProperty('SU PPU ($) Custom')){
                bool = false
            } else if (fields['Price Per Unit ($)'] && !fields['SU PPU ($) Custom'] && Number(fields['Price Per Unit ($)']?.toString()?.replace(/[^\d.]/g, '')) === 0){
                bool = false
            } else {
                bool = true
            }
        }

        return bool
    }

    const setLedsForAll = (areas) => {
        const groupedObjects = new Map();
        areas.forEach((area) => {
            area?.luminaires?.forEach((obj) => {
                const { id, qty,recomendedFixtureQty,globalRecomendedFixtureQty, fixtureMountHeight, globalQty, globalQtyLamp, ...rest } = obj;
                const key = JSON.stringify(rest);

                if (!groupedObjects.has(key)) {
                    groupedObjects.set(key, {
                        ...rest,
                        globalQty: Number(qty),
                        globalQtyLamp: Number(globalQtyLamp),
                        globalRecomendedFixtureQty: Number(recomendedFixtureQty),
                        count: 1
                    });
                } else {
                    const existing = groupedObjects.get(key);
                    groupedObjects.set(key, {
                        ...existing,
                        globalQty: existing.globalQty + Number(qty),
                        globalQtyLamp: existing.globalQtyLamp + Number(globalQtyLamp),
                        globalRecomendedFixtureQty: existing.globalRecomendedFixtureQty + Number(recomendedFixtureQty),
                        count: existing.count + 1
                    });
                }
            });
        });
        return Array.from(groupedObjects.values()).filter(
            (group) => group.count > 1
        );
    }

    const create = () => {
        let index = 0;
        let ides = setLedsForAll(area);
        let newArray = [];

        if (fromAll) {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((led) => {
                        let newObj = { ...led };

                        if (ides.length > 0) {
                            for (let j = 0; j < ides.length; j++) {
                                let { id, qty, fixtureMountHeight, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = ides[j];
                                let { id: targetId, qty: targetQty, fixtureMountHeight: targetHeight,globalRecomendedFixtureQty: targetglobalRecomendedFixtureQty, globalQty: targetGlobalQty, globalQtyLamp: targetGlobalQtyLamp, ...obj } = newObj;
                                let { globalQty: glob, globalQtyLamp: globLamp, ...lumin } = luminaire;

                                if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                    if (ides[j].id === newObj.id) {
                                        newObj = {
                                            ...newObj,
                                            ...fixture,
                                            recomendedFixtureQty: led.qty ? led.qty : 1
                                        };
                                        index = i;
                                    }
                                } else if (JSON.stringify(lumin) === JSON.stringify(obj)) {
                                    newObj = {
                                        ...newObj,
                                        ...fixture,
                                        recomendedFixtureQty:led.qty ? led.qty : 1
                                    };
                                    index = i;
                                }
                            }
                        } else {
                            let { id, qty, fixtureMountHeight, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = newObj;
                            let { globalQty: glob, globalQtyLamp: globLamp, globalRecomendedFixtureQty: globFixtureRecom, ...lumin } = luminaire;
                            if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                newObj = {
                                    ...newObj,
                                    ...fixture,
                                    recomendedFixtureQty: led.qty ? led.qty : 1
                                };
                                index = i;
                            }
                        }

                        return newObj;
                    })
                };
            });
        } else {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((luminaire) => {
                        let newObj = { ...luminaire };

                        if (newObj.id === luminaireId || ides.includes(newObj.id)) {
                            newObj = {
                                ...newObj,
                                ...fixture,
                            };
                            index = i;
                        }

                        return newObj;
                    })
                };
            });
        }
        setArea(newArray);
        // setRecent([...newArray])
    }

    useEffect(() => {
        if (createKey === true) {
            create()
            setCreateKey(false)
            onClose()
        }
    },[createKey])

    useEffect(() => {
        if (selectedItems.length > 0) {
            if (customPricing.fixture) {
                setFixture({
                    ...fixture,
                    fixtureRecomendedCustomPriceIdes: selectedItems,
                    fixtureRecomendedAirtable: fixtureAirtable?.length > 0 && JSON.stringify(fixtureAirtable[0].fields),
                })
            } else {
                setFixture({
                    ...fixture,
                    fixtureRecomendedCustomPriceIdes: [],
                })
            }
        } else {
            setFixture({
                ...fixture,
                fixtureRecomendedCustomPriceIdes: [],
            })
        }
    }, [selectedItems, fixtureAirtable])

    useEffect(() => {
        if (fixtureAirtable.length > 0) {
            setFixture({
                ...fixture,
                fixtureRecomendedAirtable: JSON.stringify(fixtureAirtable[0].fields),
                fixtureRecomendedAirtableArray: fixtureAirtable,
                fixtureRecomendedDescription: fixtureAirtable[0].fields['FE Product Description']
            })
        } else {
            setFixture({
                ...fixture,
                fixtureRecomendedAirtable: [],
                fixtureRecomendedAirtableArray: [],
                fixtureRecomendedDescription: '',
            })
        }
    },[fixtureAirtable])

    useEffect(() => {
        if (!data) return
        setFetchedDataFixture(data.data.data)
        setFixture({
            ...fixture,
            recomendedFixtureQty: luminaire.recomendedFixtureQty ? luminaire.recomendedFixtureQty : luminaire.globalQty ? luminaire.globalQty : (luminaire.qty ? luminaire.qty : 1)
        })
        setSelectedItems(fixture?.fixtureRecomendedCustomPriceIdes?.length > 0 ? fixture.fixtureRecomendedCustomPriceIdes : luminaire?.fixtureRecomendedCustomPriceIdes?.length > 0 ? luminaire.fixtureRecomendedCustomPriceIdes : [])
        setFixtureAirtable(fixture?.fixtureRecomendedAirtableArray?.length > 0 ? fixture.fixtureRecomendedAirtableArray : luminaire?.fixtureRecomendedAirtableArray?.length > 0 ? luminaire.fixtureRecomendedAirtableArray : [])
        if (dataCustomBids){
            setCustomBids(dataCustomBids.data.data)
        }
        data.data.data.forEach(item1 => {
            item1.fields['Bid Status'] = 'review'
            if (dataCustomBids){
                dataCustomBids.data.data.forEach(item2 => {
                    if (item1.id === item2.fields['Product ID']) {
                        if (item2.fields['SU PPU ($)']){
                            item1.fields['SU PPU ($) Custom'] = item2.fields['SU PPU ($)'];
                            item1.fields['SU PPU ($) Custom FE'] = item2.fields['FE PPU ($)'];
                            item1.fields['Bid Status'] = 'accepted'
                        } else {
                            item1.fields['Bid Status'] = 'pending'
                        }
                    }
                });
            }
        });
    },[dataCustomBids, data])

    if (isLoading) return <Loading height='100%'/>
    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('accessory')
                }}
                rightAction={() => {
                    create()
                    setType('lamp')
                }}
                left="Accessories"
                right="Lamp"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    Fixture Details
                </Box>
            </HStack>
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel
                    maxW="203px"
                    height='46px'
                    type="number"
                    value={fixture?.recomendedFixtureQty}
                    onChange={(e) => setFixture({
                        ...fixture,
                        recomendedFixtureQty: e.target.value
                    })}
                    pattern="[0-9]*"
                    label="QTY"/>
                <Box position='relative' width='100%' height='46px' css={styles.styledList}>
                    <Flex
                        alignItems='center'
                        onClick={() => {
                            refetch({
                                condition: {
                                    table: PRODUCT_TYPES["1"],
                                    filter: FIXTURE_FILTER(luminaire),
                                    filterMin: FIXTURE_FILTER_MIN(luminaire),
                                    projectId: project?.id,
                                }
                            })
                            setIsOpen(!isOpen)
                        }}
                        bg='#EEEEEE'
                        cursor='pointer'
                        border='1px solid #ccc'
                        borderBottom='none'
                        position='relative'
                        borderBottomLeftRadius='6px'
                        borderBottomRightRadius='6px'
                        fontSize='18px'
                        p='10px 30px'>
                        {fixtureAirtable?.length > 0 ? fixtureAirtable.map(item => item.fields["FE Product Description"]).join(', ') : "No Change"}
                        <ArrowDownIcon style={{position: 'absolute', right: '10px', top: '18px'}}/>
                    </Flex>
                    {isOpen && (
                        <ul style={{
                            position: 'absolute',
                            top: '99%',
                            left: '0',
                            right: '0',
                            border: '1px solid #ccc',
                            margin: 0,
                            padding: 0,
                            listStyle: 'none',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            maxHeight: '500px',
                            overflowY: 'auto'
                        }}>
                            {customPricing.fixture && (
                                <li style={{
                                    padding: '8px 8px 0 8px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        _checked={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _hover={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                boxShadow: 'none',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }
                                        }}
                                        _focus={{
                                            "& .chakra-checkbox__control": {
                                                background: "#FFE68D",
                                                borderColor: 'black',
                                                boxShadow: 'none'
                                            }
                                        }}
                                        _focusVisible={{
                                            "& .chakra-checkbox__control": {outline: 'none', boxShadow: 'none'}
                                        }}
                                        size='lg'
                                        iconColor='black'
                                        isChecked={selectAll}
                                        mr='8px'
                                        borderColor='#222222'
                                        onChange={handleSelectAll}>
                                        <Heading m={0} fontSize='14px' whiteSpace='nowrap'>Select All</Heading>
                                    </Checkbox>
                                </li>
                            )}
                                {fetchedDataFixture?.filter((item) => validate(item) === true).map(item => (
                                    <li
                                        key={item.id}
                                        className='list-item'
                                        style={{padding: customPricing.fixture ? '8px 8px 0 8px' : '8px 30px 0 30px',}}>
                                        {
                                            customPricing.fixture ? (
                                                <>
                                                    {item.fields['Bid Status'] === 'pending' || item.fields['Bid Status'] === 'accepted' ? (
                                                        <Flex
                                                            mr='8px'
                                                            border='2px solid black'
                                                            alignItems='center' justifyContent='center'
                                                            borderRadius='2px'
                                                            bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74' : '#FF7F56'}
                                                            width='20px' height='20px'>
                                                            {item.fields['Bid Status'] === 'pending' ? (
                                                                <PendingIcon/>
                                                            ) : (
                                                                <DollarIcon/>
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <Checkbox
                                                            _checked={{
                                                                "& .chakra-checkbox__control": {
                                                                    background: "#FFE68D",
                                                                    boxShadow: 'none',
                                                                    borderColor: 'black'
                                                                }
                                                            }}
                                                            _hover={{
                                                                "& .chakra-checkbox__control": {
                                                                    background: "#FFE68D",
                                                                    boxShadow: 'none',
                                                                    borderColor: 'black'
                                                                }
                                                            }}
                                                            size='lg'
                                                            iconColor='black'
                                                            borderColor='#222222'
                                                            isChecked={selectedItems.includes(item.id)}
                                                            onChange={() => handleSelect(item.id)}
                                                            mr='8px'>
                                                        </Checkbox>
                                                    )}

                                                    <Text as='span'
                                                          onClick={(e) => {
                                                              setFixtureAirtable((prev) => {
                                                                  if (prev.some(fixture => fixture.id === item.id)) {
                                                                      return prev.filter(fixture => fixture.id !== item.id);
                                                                  } else {
                                                                      return [item];
                                                                  }
                                                              })
                                                          }}
                                                          fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                    <Text as='span' onClick={(e) => setFixtureAirtable([item])}
                                                          fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$' + item.fields["SU PPU ($) Custom"] : ''}</Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Text
                                                        onClick={(e) => {
                                                            setFixtureAirtable((prev) => {
                                                                if (prev.some(fixture => fixture.id === item.id)) {
                                                                    return prev.filter(fixture => fixture.id !== item.id);
                                                                } else {
                                                                    return [item];
                                                                }
                                                            })
                                                        }}
                                                        as='span'
                                                        fontSize='18px'>{item.fields["FE Product Description"]}</Text>&nbsp;
                                                    <Text as='span'
                                                          fontSize='18px'>{item.fields["SU PPU ($) Custom"] ? '$' + item.fields["SU PPU ($) Custom"] : item.fields["Price Per Unit ($)"]}</Text>
                                                </>
                                            )
                                        }

                                        <Flex
                                            display={customPricing.fixture && (!item.fields["Price Per Unit ($)"] || item.fields["Price Per Unit ($)"] === '$0.00') ? 'flex' : 'none'}
                                            alignItems='center' justifyContent='center' borderRadius='2px'
                                            bg={item.fields['Bid Status'] === 'pending' ? '#FFE68D' : item.fields['Bid Status'] === 'accepted' ? '#8FCF74' : '#FF7F56'}
                                            width='20px' height='20px' position='absolute' right='30px' top='8px'>
                                            {item.fields['Bid Status'] === 'pending' ? (
                                                <PendingIcon/>
                                            ) : item.fields['Bid Status'] === 'accepted' ? (
                                                <DollarIcon/>
                                            ) : (
                                                '?'
                                            )}
                                        </Flex>
                                    </li>
                                ))}
                        </ul>
                        )}
                </Box>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl"
                     color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack justifyContent='space-between'>
                <Box/>
                <HStack spacing='7'>
                    <Heading fontSize='14px' textAlign='center' m={0} width='360px'>MINIMUM</Heading>
                    <Heading fontSize='14px' textAlign='center' m={0} width='360px'>MAXIMUM</Heading>
                </HStack>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Wattage
                </Heading>
                <HStack spacing='7'>
                    <Input
                        height='50px'
                        width='360px'
                        value={fixture?.fixtureRecomendedWattageMinimum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                wattageMin: e.target.value
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedWattageMinimum: e.target.value
                            })
                        }}
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                    <Input
                        height='50px'
                        value={fixture?.fixtureRecomendedWattageMaximum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                wattageMax: e.target.value
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedWattageMaximum: e.target.value
                            })
                        }}
                        width='360px'
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                </HStack>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <Heading fontWeight='400' color='#2C628D' fontSize='14px' m={0}>
                    Lumens
                </Heading>
                <HStack spacing='7'>
                    <Input
                        height='50px'
                        width='360px'
                        value={fixture?.fixtureRecomendedLumensMinimum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                lumensMin: e.target.value
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedLumensMinimum: e.target.value
                            })
                        }}
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                    <Input
                        height='50px'
                        value={fixture?.fixtureRecomendedLumensMaximum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                lumensMax: e.target.value
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedLumensMaximum: e.target.value
                            })
                        }}
                        width='360px'
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                </HStack>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Color
                </Heading>
                <HStack spacing='7'>
                    <Input
                        height='50px'
                        width='360px'
                        value={fixture?.fixtureRecomendedColorMinimum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                colorMin: e.target.value,
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedColorMinimum: e.target.value
                            })
                        }}
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                    <Input
                        width='360px'
                        height='50px'
                        value={fixture?.fixtureRecomendedColorMaximum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                colorMax: e.target.value,
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedColorMaximum: e.target.value
                            })
                        }}
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                </HStack>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Price
                </Heading>
                <HStack spacing='7'>
                    <Input
                        height='50px'
                        value={fixture?.fixtureRecomendedPriceMinimum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                priceMin: e.target.value,
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedPriceMinimum: e.target.value
                            })
                        }}
                        width='360px'
                        border='1px solid #A9A9A9 !important'>
                    </Input>
                    <Input
                        height='50px'
                        value={fixture?.fixtureRecomendedPriceMaximum}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                priceMax: e.target.value,
                            })
                            setFixture({
                                ...fixture,
                                fixtureRecomendedPriceMaximum: e.target.value
                            })
                        }}
                        border='1px solid #A9A9A9 !important'
                        width='360px'>
                    </Input>
                </HStack>
            </HStack>
            <HStack spacing='73px' justifyContent='space-between'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Certification
                </Heading>
                <CustomSelectMultiple
                    width='748px'
                    value={certifications}
                    setValue={setCertifications}
                    options={["UL Listed", "DLC Approved","Energy Star","FCC","ETL","RoHS","CE Marketing","Other"]}
                    />
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                    Dimmability
                </Heading>
                <Select
                    height='50px'
                    width='748px'
                    fontSize='18px'
                    placeholder='Select'
                    value={fixture?.fixtureRecomendedDimmability}
                    onChange={(e) => setFixture({
                        ...fixture,
                        fixtureRecomendedDimmability: e.target.value
                    })}
                    border='1px solid #A9A9A9 !important'>
                    <option value="None">None</option>
                    <option value="1% Dimmable">1% Dimmable</option>
                    <option value="5% Dimmable">5% Dimmable</option>
                    <option value="10% Dimmable">10% Dimmable</option>
                    <option value="Leading Edge (TRIAC)">Leading Edge (TRIAC)</option>
                    <option value="Trailing Edge (Electronic)">Trailing Edge (Electronic)</option>
                    <option value="1-10V Dimmable">1-10V Dimmable</option>
                    <option value="0-10V Dimmable">0-10V Dimmable</option>
                </Select>
            </HStack>
            <HStack spacing='5' justifyContent='space-between'>
                <HStack spacing='60px'>
                    <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                        Show Custom <br/>Price Options
                    </Heading>
                    <Checkbox
                        _checked={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        _hover={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        iconColor='black'
                        onChange={(e) => {
                            refetch()
                            if (!e.target.isChecked) {
                                setSelectedItems([])
                                setFixtureAirtable([])
                            }
                            setCustomPricing({...customPricing, fixture: !customPricing.fixture})
                        }}
                        isChecked={customPricing.fixture} color='#A9A9A9' width='20px' height='20px'
                        borderColor='#2C628D'>
                        Yes
                    </Checkbox>
                </HStack>
                <HStack spacing='40px' width='40%'>
                    <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                        Buy American
                    </Heading>
                    <Checkbox
                        _checked={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        _hover={{
                            "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                        }}
                        isChecked={buyAmerican}
                        onChange={(e) => {
                            setBuyAmerican(!buyAmerican)
                        }}
                        iconColor='black'
                        color='#A9A9A9' width='20px' height='20px' borderColor='#2C628D'>
                        Yes
                    </Checkbox>
                </HStack>
            </HStack>
        </Stack>
    )
}

export default Fixture